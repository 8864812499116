import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SocialMediaService {
    private defaultUrl: Array<any> = [
        {
            descripcion: 'Facebook',
            valor: 'https://www.facebook.com/decreditos',
        },
        {
            descripcion: 'Instagram',
            valor: 'https://www.instagram.com/decreditos/',
        },
        {
            descripcion: 'Linkedin',
            valor: 'https://ar.linkedin.com/company/decreditos',
        },
    ];

    constructor() {}

    public getUrlByName = (
        socialMedia: Array<any>,
        nombre: 'Facebook' | 'Instagram' | 'Linkedin'
    ): string => {
        const url = socialMedia.find(
            ({ descripcion }) => descripcion === nombre
        )?.valor;
        if (url) return url;

        return this.defaultUrl.find(({ descripcion }) => descripcion === nombre)
            ?.valor;
    };
}
