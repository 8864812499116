import { Component, Input } from '@angular/core';
import { SocialMediaService } from '../../../services/social-media.service';

@Component({
    selector: 'app-footer-desktop',
    templateUrl: './footer-desktop.component.html',
    styleUrls: ['./footer-desktop.component.scss'],
})
export class FooterDesktopComponent {
    @Input() public socialMedia: Array<any> = [];
    constructor(private socialMediaService: SocialMediaService) {}

    public getUrlByName = (
        nombre: 'Facebook' | 'Instagram' | 'Linkedin'
    ): string => {
        return this.socialMediaService.getUrlByName(this.socialMedia, nombre);
    };
}
