import { Component, Input } from '@angular/core';
import { SocialMediaService } from 'src/app/shared/services/social-media.service';

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent {
    @Input() public socialMedia: Array<any> = [];
    constructor(private socialMediaService: SocialMediaService) {}

    public getUrlByName = (
        nombre: 'Facebook' | 'Instagram' | 'Linkedin'
    ): string => {
        return this.socialMediaService.getUrlByName(this.socialMedia, nombre);
    };
}
