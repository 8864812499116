<mat-toolbar color="primary">
    <div class="container">
        <div class="links">
            <div class="container-link">
                <a href="https://www.decreditos.com/sobre-nosotros" target="_blank"><span>
                    Quiénes Somos
                </span></a>
            </div>
            <div class="container-link">
                <a href="http://www.bcra.gob.ar/BCRAyVos/Regimen_de_transparencia.asp" target="_blank"><span>
                    Régimen de Transparencia
                </span></a>
            </div>
            <div class="container-link">
                <a href="https://www.decreditos.com/politicas-de-privacidad" target="_blank"><span>
                    Políticas de Privacidad
                </span></a>
            </div>
            <div class="container-link">
                <a routerLink="/auth/tyc" target="_blank"><span>
                    Términos y Condiciones
                </span></a>
            </div>
            <div class="container-link">
                <a href="https://www.decreditos.com/atenci%C3%B3n-al-usuario-financiero" target="_blank"><span>
                    Información al usuario financiero
                </span></a>
            </div>
            <div class="container-link">
                <a href="https://www.decreditos.com/prestamos" target="_blank"><span>
                    Créditos Online
                </span></a>
            </div>
        </div>
        <div class="redes-y-logos">
            <div class="redes">
                <a [href]="getUrlByName('Facebook')" target="_blank">
                    <img src="/assets/images/footer/logo-facebook.svg"  alt="Logo Facebook">
                </a>
                <a [href]="getUrlByName('Instagram')" target="_blank">
                    <img  src="/assets/images/footer/logo-instagram.svg" alt="Logo Instagram">
                </a>
                <a [href]="getUrlByName('Linkedin')" target="_blank">
                    <img src="/assets/images/footer/logo-linkedin.svg"  alt="Logo Linkedin">
                </a>
            </div>
            <div class="deusados">
                <a routerLink="/">
                    <img src="/assets/images/footer/logo-deusados.svg" alt="Logo Deusados">
                </a>
            </div>
            <div class="decreditos">
                <a href="https://www.decreditos.com/" target="_blank">
                    <img src="/assets/images/footer/logo-decreditos.svg" alt="logo Decréditos">
                </a>
            </div>
        </div>
        <div class="texto-final">
            <p>Copyright © 1999-2022 Decreditos S.A. Trabajá con nosotros Términos y condiciones Cómo cuidamos tu privacidad Información al usuario financiero Ayuda Defensa del Consumidor Información sobre seguros. Av. de los Incas 5150, Piso 2, CP 1264, Parque Chas, CABA.</p>
        </div>
    </div>
</mat-toolbar>
